import React from 'react'
// import { Carousel } from 'react-bootstrap'

import './carousel.css'

import Slide1 from '../../img/poflow/shot1.png'
import Slide2 from '../../img/poflow/shot2.png'
import Slide3 from '../../img/poflow/shot3.png'


export default function MyCarousel() {
  return (
    <div className="carousel">

      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
          <img
                  className="d-block w-100 slides rounded shadow "
                  src={Slide3}
                  alt="First slide"
                  fade="true"
                />
            <div class="carousel-caption d-none d-md-block">
              <h5>BUILT SECURE</h5>
              <p>Multi-Layered Security is Standard</p>
            </div>
          </div>
          <div class="carousel-item">
          <img
                  className="d-block w-100 slides rounded shadow "
                  src={Slide2}
                  alt="Second slide"
                  fade="true"
                />
            <div class="carousel-caption d-none d-md-block">
              <h5>EASILY MANAGED</h5>
              <p>Flows, Invites, Users in One Place.</p>
            </div>
          </div>
          <div class="carousel-item">
          <img
                  className="d-block w-100 slides rounded shadow "
                  src={Slide1}
                  alt="Third slide"
                  fade="true"
                />
            <div class="carousel-caption d-none d-md-block">
              <h5>SIMPLE LAYOUT</h5>
              <p>Intuitive and Clean User Interface</p>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      
    </div>
  )
}
